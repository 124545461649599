@import '../../styling/palette';

.uncontracted-demand-table {
    .uncontracted-demand-table-cell-actual {
        color: $dark-theme-text
    }
    .uncontracted-demand-table-cell-predicted {
        color: $dark-theme-text-edit
    }
}

.clickable {
    cursor: pointer;
}
