@import '../styling/palette';

.ErrorMessage {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 5% 0 !important;
  justify-content: center;
  overflow-x: hidden;
  min-width: 50%;

  * {
    background-color: inherit;
    color: inherit;
  }

  .ErrorCard {
    width: 100%;
    height: 100%;
    padding: 10%;
    text-align: center;
  }
}

.user-menu-button {
  background-color: $dark-theme-background !important;

  .user-menu-button-avatar {
    background-color: $dark-theme-primary;
    color: $dark-theme-background;
  }
}

.mrp-graph {
  .recharts-tooltip-wrapper {
    background: $dark-theme-background-paper;
    border-radius: 2rem;
    padding: 0.5rem;
    border: 1px solid $dark-theme-text-disabled;
    font-size: 14px;
    span {
      color: $dark-theme-primary;
    }
    .red {
      color: $dark-theme-error;
    }
  }
}

.mrp-table {
  width: fit-content;
  .mrp-table-clickable-value {
    cursor: pointer;
  }
  .mrp-table-cell-incoming-po {
    color: $dark-theme-color-code-pink;
    cursor: pointer;
  }
  .mrp-table-cell-incoming-suggestion {
    cursor: pointer;
    color: $dark-theme-color-code-cyan;
  }
  .mrp-table-cell-incoming-general-production {
    color: $dark-theme-color-code-orange;
    cursor: pointer;
  }
  .mrp-table-cell-incoming-po-general-production {
    cursor: pointer;
    color: $dark-theme-color-code-yellow;
  }
  .mrp-table-cell-incoming-po-suggestion {
    cursor: pointer;
    color: $dark-theme-color-code-light-green;
  }
  .mrp-table-cell-incoming-suggestion-general-production {
    cursor: pointer;
    color: $dark-theme-color-code-yellow-green;
  }
  .mrp-table-cell-incoming-po-general-production-suggestion {
    cursor: pointer;
    color: $dark-theme-color-code-dark-cyan;
  }
  .mrp-table-cell-over-stock {
    color: $dark-theme-color-code-purple;
  }
  .mrp-table-cell-under-stock{
    color: $dark-theme-error;
  }
}

.mrp-modal .MuiBox-root {
  border-radius: 0.9rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $dark-theme-background-paper;
}
