@import '../../styling/palette';

.fulfillment-page {
  padding: 1rem;

  .fulfillment-page-header{
    width: 100%;

    .filter-chip-area {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.2rem;
    }
  }

  .clickable {
    cursor: pointer;
}

.fulfillment-mode-selection {
    background-color: $dark-theme-background-paper !important;
    border-radius: 3rem;
    width: 14rem;
    height: 2rem;

    .MuiSelect-icon {
        color: $dark-theme-text
    }
}

.filter-container {
    width: inherit;
    height: inherit;

    .MuiListItem-padding {
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .MuiCard-root {
      height: inherit;
      overflow-y: auto;
    }
    .MuiRadio-root {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    p.MuiTypography-root {
      text-align: start;
      font-weight: bold;
      font-size: 18px;
      padding-top: 10px;
    }
    .filters-checkbox {
      padding-top: 0;
      padding-bottom: 0;
    }

    .filters-section {
      flex-wrap: nowrap;
      .MuiTypography-root {
        font-size: 0.85rem;
      }
      .filter-search-box {
        input{
          font-size: 0.9rem;
        }
      }
    }
  }
}
