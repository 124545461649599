@import '../../styling/palette';

.dashboard-page {
  padding: 1rem;

  .dashboard-page-header {
    width: 100%;
    padding-bottom: 0.5rem;
    a {
      text-decoration: none;
      color: $dark-theme-primary;
      font-weight: 500;
      font-size: 0.8125rem;
    }
    .filter-chip-area {
      flex: 1;
      justify-content: flex-start;
      display: flex;
      flex-wrap: wrap;
      row-gap: 0.2rem;
    }
  }

  .dashboard-kpi {
    height: inherit;
    width: 90%;
    border-radius: 0.9rem;

    .kpi-value {
      font-weight: bold;
    }

    .kpi-title {
      color: $dark-theme-text-disabled;
      font-size: 14px;
    }

    .kpi-subtitle {
      color: $dark-theme-text-disabled;
      font-size: 13px;
    }
  }
}
