@import '../../styling/palette';

.mrp-page {
  .filter-container {
    width: inherit;
    height: inherit;

    .MuiListItem-padding {
      padding-top: 1px;
      padding-bottom: 1px;
    }
    .MuiCard-root {
      height: inherit;
      overflow-y: auto;
    }
    .MuiRadio-root {
      padding-top: 2px;
      padding-bottom: 2px;
    }
    p.MuiTypography-root {
      text-align: start;
      font-weight: bold;
      font-size: 18px;
      padding-top: 10px;
    }
    .filters-checkbox {
      padding-top: 0;
      padding-bottom: 0;
    }

    .filters-section {
      flex-wrap: nowrap;
      .MuiTypography-root {
        font-size: 0.85rem;
      }
      .filter-search-box {
        input{
          font-size: 0.9rem;
        }
      }
    }
  }

  .mrp-graph-title {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    font-size: 15px;
  }

  .mrp-tables-header {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    justify-content: space-between;

    .toggles-area {
      align-items: center;
      width: 100%;
    }

    .mrp-page-color-legend {
      font-size: 0.8rem;
    }
    .mrp-download-data {
      a {
        text-decoration: none;
        color: $dark-theme-primary;
        font-weight: 500;
        font-size: 0.8125rem;
      }
    }

    .mrp-tables-sort-selection {
      background-color: $dark-theme-background-paper !important;
      border-radius: 3rem;
      width: 14rem;
      height: 2rem;

      .MuiSelect-icon {
        color: $dark-theme-text
      }
    }
  }

  .mrp-tables-container {
    height: 85%;
    width: 100%;
    grid-template-columns: 1fr;

    .spec-table-area.selected {
      background-color: $dark-theme-background-paper;
      .spec-title {
        font-weight: 600;
      }
    }

    .spec-table-area {
      display: flex;
      align-items: flex-start;
      padding: 0.5rem;

      .spec-title {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        width: 100%;
        .spec-item-code {
          cursor: pointer;
          font-size: 0.9rem;
        }
      }
    }
  }
}

.mrp-table-cell-details {
  padding: 0.5rem;
}


.mrp-cell-drilldown-drawer {
  width: 70%;
  padding: 1rem;

  .mrp-cell-drilldown-drawer-section-title {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    span {
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  .incoming-table-total-row {
    font-weight: bold;
    background-color: $dark-theme-background-secondary !important;
  }
}
