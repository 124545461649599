@import '../../styling/palette';

.insights-drill-down-drawer {
  width: 45%;

  .insights-drill-down-drawer-content {
    padding: 0.5rem;
    height: inherit;

    .production-recommendation-table-aggregate-row {
      font-weight: bold;
      background-color: $dark-theme-background-secondary;
    }

    .contract-orders-graph-toggle-button {
      background-color: $dark-theme-background !important;
    }
  }

  .clickable {
    cursor: pointer;
  }
}

.insights-table-row-clickable {
  cursor: pointer;
}
