@import '../../styling/palette';

.settings-screen {
  height: 100%;
    .settings-container {
      .disabled {
        display: none;
      }
       display: flex;
       flex-direction: column;
       padding: 1rem;
       gap: 1rem;
       .settings-category-card {
           .settings-category-card-title {
               text-align: start;
           }
           .settings-category-card-content {
               .setting {
                   display: flex;
                   flex-direction: column;
                   align-items: flex-start;
                   gap: 1rem;
                   padding: 1rem;
                   .setting-input {
                       .MuiInputBase-root {
                           margin: 0.5rem;
                       }
                   }
               }
           }
       }
    }
}
