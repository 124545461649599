@import '../../styling/palette';

.contract {
   
    .contract-section-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .contract-kpi-container {
        height: 100%;
        .MuiCardHeader-root {
            padding: 0.8rem;

            .MuiTypography-root {
                font-weight: 600;
            }
        }
        .MuiCardContent-root {
            height: 5rem;
            padding: 0 0.8rem;
            font-size: 3rem;
        }

        .demand-fulfillment-gauge {
            .demand-fulfillment-gauge-text {
                font-size: 1rem;
            }

            .demand-fulfillment-gauge-ratio-text {
                font-size: 1.25rem;
            }
        }

        .MuiCardActions-root {
            justify-content: center;
        }
    }

    .contract-orders-graph-toggle-button {
        background-color: $dark-theme-background !important;
    }
}

.contract-detailed-drawer {
    padding: 0.5rem;
    width: 60vw;
    .contract-section-title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    .clickable {
        cursor: pointer;
    }
}
