.App {
  text-align: center;  
}

.app-header {
  height: 4rem;
}

.app-header-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-body {
  height: calc(100vh - 4rem);
}

::-webkit-scrollbar {
  width: 0.63rem;
  height: 0.63rem;
  background-color: #202847;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0,0,0,1);
  
}

::-webkit-scrollbar-thumb {
  background-color: #08E0EA;
  height: 10px;
  border-radius: 12px;
}

::-webkit-scrollbar-corner {
  background-color: inherit;
}

input {
  color-scheme: dark;
}
