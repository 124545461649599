$dark-theme-background: #110B2E;
$dark-theme-background-paper: #202847;
$dark-theme-background-secondary: #272241;
$dark-theme-background-table-header: #1E5972;
$dark-theme-text: #FFFFFF;
$dark-theme-text-disabled: #AAAAAA;
$dark-theme-primary: #08E0EA;
$dark-theme-primary-gradient: linear-gradient(180deg, #08E0EA 0%, #678BFD 100%);
$dark-theme-error: #FE5530;
$dark-theme-error-background: #FF535F26;
$dark-theme-success: #43D508;
$dark-theme-success-background: #4EFF0026;
$dark-theme-text-edit: #EF9B1E;
$dark-theme-color-code-pink: #E75978;
$dark-theme-color-code-cyan: #30FEE5;
$dark-theme-color-code-purple: #678BFD;
$dark-theme-color-code-orange: #EA6A47;
$dark-theme-color-code-yellow: #DBAE58;
$dark-theme-color-code-light-green: #6AB187;
$dark-theme-color-code-yellow-green: #B3C100;
$dark-theme-color-code-dark-cyan: #4CD5F5;

:export {
    DarkThemeBackground: $dark-theme-background;
    DarkThemeBackgroundPaper: $dark-theme-background-paper;
    DarkThemeBackgroundSecondary: $dark-theme-background-secondary;
    DarkThemeBackgroundTableHeader: $dark-theme-background-table-header;
    DarkThemeText: $dark-theme-text;
    DarkThemeTextDisabled: $dark-theme-text-disabled;
    DarkThemePrimary: $dark-theme-primary;
    DarkThemePrimaryGradient: $dark-theme-primary-gradient;
    DarkThemeError: $dark-theme-error;
    DarkThemeErrorBackground: $dark-theme-error-background;
    DarkThemeSuccess: $dark-theme-success;
    DarkThemeSuccessBackground: $dark-theme-success-background;
    DarkThemeTextEdit: $dark-theme-text-edit;
    DarkThemeColorCodePink: $dark-theme-color-code-pink;
    DarkThemeColorCodeCyan: $dark-theme-color-code-cyan;
    DarkThemeColorCodePurple: $dark-theme-color-code-purple;
    DarkThemeColorCodeOrange: $dark-theme-color-code-orange;
    DarkThemeColorCodeYellow: $dark-theme-color-code-yellow;
    DarkThemeColorCodeLightGreen: $dark-theme-color-code-light-green;
    DarkThemeColorCodeYellowGreen: $dark-theme-color-code-yellow-green;
    DarkThemeColorCodeDarkCyan: $dark-theme-color-code-dark-cyan;
}
